
import BButton from 'buetify/lib/components/button/BButton';
import BTooltip from 'buetify/lib/components/tooltip';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'tooltips-styles-example',
	components: {
		BTooltip,
		BButton
	}
});
