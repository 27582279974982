
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import { ApiView } from '../../../../components/apiView';
import ExampleView from '../../../../components/exampleView/ExampleView.vue';
import { apis } from './apis';
import MultilinedExample from './examples/MultilinedExample.vue';
import MultilinedExampleCode from '!!raw-loader!./examples/MultilinedExample.vue';
import SimpleExample from './examples/SimpleExample.vue';
import SimpleExampleCode from '!!raw-loader!./examples/SimpleExample.vue';
import StylesExampleCode from '!!raw-loader!./examples/StylesExample.vue';
import StylesExample from './examples/StylesExample.vue';

export default defineComponent({
	name: 'tooltip-documentation',
	components: {
		ExampleView,
		ApiView,
		MultilinedExample,
		SimpleExample,
		BHorizontalDivider,
		StylesExample
	},
	setup() {
		return {
			apis,
			MultilinedExampleCode,
			SimpleExampleCode,
			StylesExampleCode
		};
	}
});
